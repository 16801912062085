
import {defineComponent} from "vue";

export default defineComponent({
  name: "TimetableTitle",
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
