
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "TimetableCheckbox",
  data() {
    return {}
  },
  props: {
    value: {
      required: false,
      default: false,
      type: Boolean,
    },
    label: {
      required: false,
      default: null,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    }
  },
  setup(props, context) {

    let checkRef:any = ref(props.value);

    watch(() => props.value, (value, oldValue) => {
      checkRef.value = value;
    })

    watch(() => checkRef.value, value => {
      context.emit('update:value', value);
      context.emit('change', value);
    })

    const onChange = () => {
      context.emit('click');
      if (props.disabled){
        return;
      }
     checkRef.value = !checkRef.value;
    }

    return {
      checkRef,
      onChange,
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
