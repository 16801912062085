<template>
  <div class="close-icon" @click="onBack">
    <CloseCircleFilled/>
  </div>
</template>

<script>
import {CloseCircleFilled} from '@ant-design/icons-vue'
import {useRouter} from "vue-router";
export default {
  name: "CloseIcon",
  components:{
    CloseCircleFilled
  },
  data() {
    return {}
  },
  setup(){
    const router = useRouter();
    const onBack = () =>{
      router.back();
    }
    return {
      onBack
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
}
</script>

<style lang="less" scoped>
.close-icon {
  position: absolute;
  top: 35px;
  right: 60px;
  font-size: 44px;
  cursor: pointer;
  z-index: 999;
  color: #FFF;
}
</style>
